<template>
  <div class="flex overflow-hidden relative">
    <div class="py-8 pr-7 w-full" :class="sideModal === true ? 'mr-72' : ''">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Branch List</h1>
        <button
          @click="addNewBranch()"
          class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
        >
          <plus class="mr-5" />
          Add Branch
        </button>
      </div>
      <div class="" v-if="branches.length !== 0">
        <branch-table
          :allBranches="branches"
          :perPage="branchesPerPage"
          :currentPage="currentPage"
          :total="totalBranches"
          @fetchPage="fetchAllBranches"
          @revoke="revokeBranch"
          @edit="editBranch"
          @delete="deleteBranch"
        />
      </div>
      <div class="h-full w-full flex items-center justify-center" v-else>
        <Spinner />
      </div>
    </div>
    <edit-branch v-if="sideModal" :branch="editBranchDetail" />
    <add-branch
      v-if="sideModal && overlay"
      @close="closeNewBranch"
      @created="fetchAllBranches"
      :branch_edit="sideModalData"
    />
  </div>
</template>

<script>
import { fetchBranches, deleteABranch } from "@/services/branch";

export default {
  name: "BranchIndex",
  components: {
    plus: () => import("@/assets/icons/PlusIcon.vue"),
    BranchTable: () => import("@/components/Branch/BranchTable.vue"),
    AddBranch: () => import("@/components/Branch/AddNewBranchModal.vue"),
    EditBranch: () => import("@/components/Branch/EditBranchModal.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
  },
  data() {
    return {
      sideModal: false,
      sideModalData: {},
      overlay: false,
      branches: [],
      totalBranches: 0,
      branchesPerPage: 0,
      currentPage: 0,
      editBranchDetail: {},
      submitting: false,
    };
  },
  methods: {
    addNewBranch() {
      this.sideModal = true;
      this.overlay = true;
    },
    closeNewBranch() {
      this.sideModal = false;
      this.overlay = false;
      this.sideModalData = {};
    },
    editBranch(branch) {
      this.sideModal = true;
      this.editBranchDetail = branch;
    },
    revokeBranch(branch) {
      this.sideModalData = branch;
      this.overlay = true;
      this.sideModal = true;
    },
    async deleteBranch(branch) {
      try {
        await deleteABranch(branch.id);
        this.$toast.success("Branch deleted");
        this.fetchAllBranches();
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
    async fetchAllBranches(page = 1) {
      this.sideModal = false;
      this.sideModalData = {};
      try {
        const response = await fetchBranches(page);
        this.branches = response.data.branch.data;
        this.branchesPerPage = response.data.branch.per_page;
        this.totalBranches = response.data.branch.total;
        this.currentPage = response.data.branch.current_page;
      } catch (error) {
        console.log(error);
      }
      this.$store.commit("SET_BRANCHES", this.branches);
    },
  },
  mounted() {
    this.fetchAllBranches();
  },
};
</script>
